import * as CSS from 'csstype'
import CloseButton from '../components/CloseButton'
import { isLocalStorageAvailable } from '../utils/helpers'
import Visitor from './Visitor'
import Publisher from './Publisher'
import API from './API'
import {
  Position,
  Status,
  Widget,
  WidgetAnimation,
  Animation,
  Pod,
  WidgetType,
  OperatorType,
  AttributeValues,
} from '../types/shared'
import TopWorker from '../utils/top.worker.js'
import { VERSION } from '../utils/status'
import { WidgetStyle } from '@top/shared_deprecated/src/types/SDK'
import { UserAttributes } from './API'
import { CannotCloseWidgetError } from '../errors/CannotCloseWidget'

type ShowOptions = {
  /**
   * If `true` will close currently open (rendered) widgets and open a new one.
   * @default false
   */
  closeAllOpen?: boolean
  /**
   * If `true`, `ACTIVITY_COLLAPSED` and `ACTIVITY_DISMISS` events will **not** be logged.
   * @default false
   */
  suppressCloseEvents?: boolean
}

const defaultShowOptions = {
  closeAllOpen: false,
  suppressCloseEvents: false,
} as const satisfies Required<ShowOptions>

type CloseWidgetOptions = Pick<ShowOptions, 'suppressCloseEvents'>

const defaultCloseWidgetOptions = {
  suppressCloseEvents: false,
} as const satisfies Required<CloseWidgetOptions>

enum PostMessageCommands {
  SetVisitor = 'SET_VISITOR',
  ActivityCollapsed = 'ACTIVITY_COLLAPSED',
  ActivityCompleted = 'ActivityCompleted',
  SetOrigin = 'SET_ORIGIN',
  TopIsReady = 'TOPIsReady',
}

interface Settings {
  defaultDelay?: number
  animationDuration?: string
  // autoDisplay?: boolean;
  disableOnSmallScreens?: boolean
  disableTracking?: boolean
  disable?: boolean
  // only for testing dev distributor
  devDistributor?: string
  closeDelay?: number | boolean
  containerStyle?: CSS.Properties
  animation?: {
    entrance: Animation
    exit: Animation
  }
  disableAnimation?: boolean
  /**
   * Locale override to be passed as part of the distribution url.
   * Setting this to a value will make distributor bypass all language detection.
   * There's no validation for supported or valid locale!
   */
  locale?: string
  /** @default true */
  showCloseButton?: boolean
}
interface InitializeOptions {
  settings?: Settings
  visitor?: {
    id?: string
    [key: string]: string | number | boolean | undefined
  }
  publisher: {
    app_id: string
    pod: Pod
    [key: string]: string | number | boolean | undefined
  }
  user_attributes?: {
    [key: string]: string
  }
}

const PositionClassMap: { [position in Position]: string } = {
  [Position.TopLeft]: 'position--top-left',
  [Position.TopRight]: 'position--top-right',
  [Position.BottomLeft]: 'position--bottom-left',
  [Position.BottomRight]: 'position--bottom-right',
  [Position.Centre]: 'position--centre',
  [Position.Embedded]: 'position--embedded',
}
const PositionAnimationMap: {
  [position in Position]: WidgetAnimation
} = {
  [Position.TopLeft]: {
    entrance: Animation.SlideInDown,
    exit: Animation.SlideOutUp,
  },
  [Position.TopRight]: {
    entrance: Animation.SlideInDown,
    exit: Animation.SlideOutUp,
  },
  [Position.BottomLeft]: {
    entrance: Animation.SlideInUp,
    exit: Animation.SlideOutDown,
  },
  [Position.BottomRight]: {
    entrance: Animation.SlideInUp,
    exit: Animation.SlideOutDown,
  },
  [Position.Centre]: {
    entrance: Animation.ZoomIn,
    exit: Animation.ZoomOut,
  },
  [Position.Embedded]: {
    entrance: Animation.None,
    exit: Animation.None,
  },
}

enum Events {
  ready = 'TouchpointLoaded',
}

export interface State {
  location: string
  widgets: {
    [path: string]: Widget
  }
  openWidgetIds: string[]
  currentWidget:
    | undefined
    | {
        data: Widget
        element: HTMLElement
        iframe: HTMLIFrameElement
      }
}

class Touchpoint {
  private settings: Settings = {
    defaultDelay: 1000,
    animationDuration: '1s',
    disable: false,
    devDistributor: undefined,
    closeDelay: 5000,
    disableOnSmallScreens: false,
    containerStyle: {},
    disableAnimation: false,
    disableTracking: false,
  }
  private readonly MOBILE_WIDTH_LIMIT = 768
  public readonly version: string = VERSION
  private visitor: Visitor | undefined = undefined
  public publisher: Publisher | undefined = undefined
  private user_attributes: UserAttributes | undefined = undefined
  private api: API
  private _state: State = {
    location: '',
    widgets: {},
    currentWidget: undefined,
    openWidgetIds: [],
  }
  private locationWidgetMap: { [location: string]: string } = {}
  private locationEmbeddedWidgetMap: { [location: string]: string } = {}
  private worker: Worker
  private get state() {
    return this._state
  }
  private set state(state: State) {
    this._state = state
  }
  public initialize: (options: InitializeOptions) => void = async (options) => {
    const { publisher, visitor } = options
    const settings = {
      ...this.settings,
      ...(options.settings && {
        ...options.settings,
        showCloseButton: options.settings.showCloseButton ?? true,
      }),
    } satisfies Settings
    const user_attributes = {
      ...this.user_attributes,
      ...(options.user_attributes && { ...options.user_attributes }),
    }
    if (settings?.disable) {
      // because of any reason we don't want to show widgets to this user
      return false
    }
    if (!publisher?.app_id) {
      throw new Error('No app id provided for touchpoint SDK')
    }
    if (!publisher?.pod) {
      throw new Error('No Pod provided for touchpoint SDK')
    }
    if (window.screen.width < this.MOBILE_WIDTH_LIMIT && settings.disableOnSmallScreens) {
      // the customers don't want to show it on the mobile
      return
    }

    this.publisher = new Publisher({ ...publisher })

    // we can't track the visitor, so nothing
    if (!isLocalStorageAvailable() && !visitor?.id) {
      // Generating too many sentry alerts - if we have plans to use this data, we can turn back on
      // alert("Can't track the visitor", false, {}, Severity.Warning)
      return false
    }
    this.settings = { ...settings }
    this.setAnimationDuration()
    if (visitor) {
      this.visitor = new Visitor(
        {
          ...visitor,
        },
        { disableTracking: this.settings.disableTracking }
      )
    }
    this.api = new API({
      publisher: this.publisher,
      visitor: this.visitor,
    })

    this.user_attributes = { ...user_attributes }

    // listen to commands from distributor
    window.addEventListener('message', this.onReceiveMessage)

    await this.loadWidgets()
    await this.postAttributes()
    this.worker = new TopWorker()

    this.worker.onmessage = (e: { data?: { widgetId: string } }) => {
      const id = e?.data?.widgetId
      if (e?.data?.widgetId && this.state.currentWidget === undefined) {
        const widget = this.state.widgets[id]
        const showWidget = this.checkUserAttributes(id)

        if (widget.status === Status.new && showWidget) {
          this.renderWidget(widget)
        }
      }
    }

    this.trackChangeLocation()
    // trigger an event to let the Browser know Touchpoint is ready
    this.onReady()
  }

  public getEmbeddedDistURL() {
    const currentLocation = window.location.pathname
    const matches = Object.keys(this.locationEmbeddedWidgetMap).filter((location) =>
      new RegExp(location).test(currentLocation)
    )
    if (matches?.length > 0) {
      // for now we only support the first match
      const widgetID = this.locationEmbeddedWidgetMap[matches[0]]
      return this.getDistributionURL(this.state.widgets[widgetID].distribution_url)
    }
    return null
  }

  // Attribute checking

  private isEqual = (
    values: AttributeValues[],
    visitor: AttributeValues,
    operator: OperatorType
  ) => {
    // if empty array return
    if (!values.length) return

    for (const value of values) {
      switch (operator) {
        case OperatorType.IS:
          if (value === visitor) return true
          break
        case OperatorType.IS_NOT:
          if (value !== visitor) return true
          break
        default:
          throw new Error(`Invalid Operator Type ${operator}`)
      }
    }
    return false
  }

  private isLessOrGreater = (values: number[], visitor: number, operator: OperatorType) => {
    // if empty array return
    if (!values.length) return

    // if array length is > 1 throw error
    if (values.length > 1) {
      throw new Error(`Targeting values cannot be more than 1 for operator: ${operator}`)
    }

    const rule = values[0]

    switch (operator) {
      case OperatorType.GT:
        return visitor > rule
      case OperatorType.GTE:
        return visitor >= rule
      case OperatorType.LT:
        return visitor < rule
      case OperatorType.LTE:
        return visitor <= rule
      default:
        throw new Error(`Invalid Operator Type ${operator}`)
    }
  }

  private isBeforeOrAfter = (
    values: AttributeValues[],
    visitor: AttributeValues,
    operator: OperatorType
  ) => {
    if (typeof visitor !== 'string' && (visitor as any) instanceof Date === false)
      throw new Error(`Invalid data format for operator: ${operator}`)

    // if empty array return
    if (!values.length) return

    // if array length is > 1 throw error
    if (values.length > 1) {
      throw new Error(`Targeting values cannot be more than 1 for operator: ${operator}`)
    }

    const rule = values[0]
    const stringDate = typeof visitor === 'string' && Date.parse(visitor) ? visitor : undefined
    const date = !stringDate && visitor instanceof Date ? visitor : undefined

    if (!stringDate && !date) {
      throw new Error(`Invalid date format for visitor value: ${visitor}`)
    }

    const newDate = visitor instanceof Date ? visitor.toISOString() : visitor

    switch (operator) {
      case OperatorType.AFTER:
        return newDate > rule
      case OperatorType.ON_OR_AFTER:
        return newDate >= rule
      case OperatorType.BEFORE:
        return newDate < rule
      case OperatorType.ON_OR_BEFORE:
        return newDate <= rule
      default:
        throw new Error(`Invalid Operator Type ${operator}`)
    }
  }

  // string data types
  private isIncluded = (values: string[], visitor: string, operator: OperatorType) => {
    // if empty array return
    if (!values.length) return

    for (const value of values) {
      switch (operator) {
        case OperatorType.CONTAINS:
          if (visitor.includes(value)) return true
          break
        case OperatorType.DOES_NOT_CONTAIN:
          if (!visitor.includes(value)) return true
          break
        default:
          throw new Error(`Invalid Operator Type ${operator}`)
      }
    }
    return false
  }

  // only applies to string data type
  private isPartialMatch = (values: string[], visitor: string, operator: OperatorType) => {
    // if empty array return
    if (!values.length) return

    for (const value of values) {
      switch (operator) {
        case OperatorType.STARTS_WITH:
          if (visitor.startsWith(value)) return true
          break
        case OperatorType.ENDS_WITH:
          if (visitor.endsWith(value)) return true
          break
        default:
          throw new Error(`Invalid Operator Type ${operator}`)
      }
    }
    return false
  }

  private checkUserAttributes = (widgetId: string) => {
    const widget = this.getWidget(widgetId)

    // if widget doesn't have targeting_rules, show it to everyone
    if (!widget.targeting_rules) return true

    // if there is no visitor payload, no need to continue
    if (!this.visitor) return false

    // otherwise, compare targeting rules to visitor payload
    for (const rule of widget.targeting_rules) {
      if (!rule.attribute_values) return

      const key = rule.attribute.attribute_name
      const operator = rule.operator_type
      const values = rule.attribute_values
      const visitorRule = this.visitor.other[key]

      // if rule doesn't exist in payload, return
      if (visitorRule === undefined) return

      switch (operator) {
        case OperatorType.IS:
        case OperatorType.IS_NOT:
          if (!this.isEqual(values, visitorRule, operator)) return false
          break
        case OperatorType.GT:
        case OperatorType.GTE:
        case OperatorType.LT:
        case OperatorType.LTE:
          if (typeof visitorRule !== 'number')
            throw new Error(`Invalid data type for operator: ${operator}`)
          if (!this.isLessOrGreater(values as number[], visitorRule, operator)) return false
          break
        case OperatorType.CONTAINS:
        case OperatorType.DOES_NOT_CONTAIN:
          if (typeof visitorRule !== 'string')
            throw new Error(`Invalid data type for operator: ${operator}`)
          if (!this.isIncluded(values as string[], visitorRule, operator)) return false
          break
        case OperatorType.STARTS_WITH:
        case OperatorType.ENDS_WITH:
          if (typeof visitorRule !== 'string')
            throw new Error(`Invalid data type for operator: ${operator}`)
          if (!this.isPartialMatch(values as string[], visitorRule, operator)) return false
          break
        case OperatorType.AFTER:
        case OperatorType.ON_OR_BEFORE:
        case OperatorType.BEFORE:
        case OperatorType.ON_OR_AFTER:
          if (!this.isBeforeOrAfter(values, visitorRule, operator)) return false
          break
        default:
          throw new Error(`Invalid Operator Type ${operator}`)
      }
    }
    return true
  }

  private trackChangeLocation: () => void = () => {
    setInterval(() => {
      const currentLocation = this?.state?.location
      const newLocation = window.location.pathname
      if (currentLocation !== newLocation) {
        this.state = { ...this.state, location: newLocation }
        if (
          this.state.currentWidget &&
          this.state.currentWidget.data?.type === WidgetType.PathBased
        ) {
          this.closeCurrentWidget()
        }
        this.worker.postMessage({
          location: newLocation,
          map: this.locationWidgetMap,
        })
      }
    }, 1000)
  }

  private loadWidgets: () => void = async () => {
    return this.api.getWidgets().then((widgets: { [path: string]: Widget } | undefined) => {
      this.state = { ...this.state, widgets }
      if (widgets) {
        Object.entries(widgets).forEach(([id, widget]) => {
          // Embedded widgets are handled differently, so need to be put in a separate map
          if (widget.position === Position.Embedded) {
            this.locationEmbeddedWidgetMap = widget.pathsRegex
              ? { ...this.locationEmbeddedWidgetMap, [widget.pathsRegex]: id }
              : { ...this.locationEmbeddedWidgetMap, [widget.path]: id }
          } else {
            this.locationWidgetMap = widget.pathsRegex
              ? { ...this.locationWidgetMap, [widget.pathsRegex]: id }
              : { ...this.locationWidgetMap, [widget.path]: id }
          }
        }, {})
      }
    })
  }

  private postAttributes = async () => {
    const attr = Object.keys(this.user_attributes).length
    if (!attr) return
    return this.api.postUserAttributes(this.user_attributes)
  }

  private onReady() {
    window.dispatchEvent(new Event(Events.ready))
  }

  private closeAllOpen(options: CloseWidgetOptions) {
    const { suppressCloseEvents } = options
    const { openWidgetIds } = this.state

    openWidgetIds.forEach((widgetId) => {
      this.closeWidget(widgetId, { suppressCloseEvents })
    })
  }

  parseShowOptions(options?: ShowOptions) {
    if (
      (options?.closeAllOpen === undefined || !options?.closeAllOpen) &&
      options?.suppressCloseEvents
    ) {
      console.warn(
        'Touchpoint SDK > show:  Setting "suppressCloseEvents" without "closeAllOpen" does nothing.'
      )
    }

    return { ...defaultShowOptions, ...options }
  }

  public show(widgetId: string, options?: ShowOptions) {
    const { closeAllOpen, suppressCloseEvents } = this.parseShowOptions(options)

    if (closeAllOpen) {
      this.closeAllOpen({ suppressCloseEvents })
    }

    const widget = this.getWidget(widgetId)
    if (widget) {
      if (widget.status !== Status.new && !this.settings.disableTracking && !widget.always_show) {
        return
      }
      const showWidget = this.checkUserAttributes(widgetId)
      if (showWidget) this.renderWidget(widget)
    }
  }
  public exists(widgetId: string) {
    return !!this.state.widgets[widgetId]
  }
  public getWidget(widgetId: string) {
    return this.state.widgets[widgetId]
  }

  private isWidgetOpen(widget: Widget) {
    return this.state.openWidgetIds.indexOf(widget.id) !== -1
  }

  private removeWidgetFromOpenList(widgetId: string) {
    let openWidgetIds = [...this.state.openWidgetIds]
    openWidgetIds = openWidgetIds.filter((id) => id !== widgetId)
    this.state = {
      ...this.state,
      openWidgetIds,
    }
  }

  private addWidgetIdToOpenList(widgetId: string) {
    const openWidgetIds = [...this.state.openWidgetIds]
    openWidgetIds.push(widgetId)
    this.state = {
      ...this.state,
      openWidgetIds,
    }
  }

  private renderWidget(widget: Widget) {
    if (this.isWidgetOpen(widget)) {
      return false
    }

    this.addWidgetIdToOpenList(widget.id)

    const {
      style,
      distribution_url,
      animation,
      position = Position.BottomRight,
    }: Widget = {
      ...widget,
    }
    let _animation: Animation
    if (this.settings.animation) {
      // user changed the default animations the animation
      _animation = this.settings.animation.entrance
    } else {
      _animation = animation?.entrance || PositionAnimationMap[position].entrance
    }

    const body = document.body
    const iframe = document.createElement('iframe')
    const iframeContainer = document.createElement('div')

    if (this.settings.showCloseButton) {
      const closeButton = CloseButton({
        onClick: () => this.closeWidget(widget.id),
      })

      iframeContainer.appendChild(closeButton)
    }

    iframeContainer.id = `iframe-container-${widget.id}`
    iframeContainer.className = 'touchpoint iframeContainer'
    if (style) {
      this.applyStyle(style, iframeContainer)
    }
    iframeContainer.style.display = 'none'

    const dist_url = this.getDistributionURL(distribution_url)
    iframe.onload = () => {
      iframeContainer.classList.add(PositionClassMap[position])
      setTimeout(() => {
        iframeContainer.style.display = 'block'
        if (!this.settings.disableAnimation) {
          iframeContainer.classList.add(_animation)
        }
      }, widget.delay_in_ms || this.settings.defaultDelay || 1000)
      if (this.visitor) {
        iframe.contentWindow.postMessage(
          {
            type: PostMessageCommands.SetVisitor,
            visitor: { id: this.visitor.id, ...this.visitor.other },
          },
          dist_url
        )
      }
      iframe.contentWindow.postMessage(
        {
          type: PostMessageCommands.SetOrigin,
          origin: window.location.origin,
        },
        dist_url
      )
    }
    iframe.id = `iframe-${widget.id}`
    iframe.src = dist_url
    iframe.width = '100%'
    iframe.height = '100%'
    iframe.style.border = 'none'
    iframe.sandbox.add('allow-scripts')
    iframe.sandbox.add('allow-popups')
    iframe.sandbox.add('allow-same-origin')
    iframe.sandbox.add('allow-forms')
    iframeContainer.appendChild(iframe)
    body.appendChild(iframeContainer)
    this.state = {
      ...this.state,
      currentWidget: { data: widget, element: iframeContainer, iframe },
    }
  }

  // using same breakpoints as in the CSS file
  private readonly MOBILE = 480
  private readonly TABLET = 839
  private applyStyle: (style: WidgetStyle, container: HTMLDivElement) => void = (
    style,
    container
  ) => {
    const width = window.screen.width
    if (style.sizes) {
      if (width <= this.MOBILE) {
        const noBorderRadius = style.sizes.mobile.height === `100%`
        const overRideStyles: CSS.Properties = {
          ...(noBorderRadius && { borderRadius: 0 }),
        }
        Object.assign(
          container.style,
          style.sizes.mobile,
          this.settings.containerStyle,
          overRideStyles
        )
        if (style.sizes.mobile.width !== 'auto') {
          // needs to be important to work with legacy styles
          container.style.setProperty('width', style.sizes.mobile.width, 'important')
        }
      } else if (width <= this.TABLET) {
        Object.assign(container.style, style.sizes.tablet, this.settings.containerStyle)
      } else {
        Object.assign(container.style, style.sizes.desktop, this.settings.containerStyle)
      }
    } else {
      // to support existing widgets that were published using height/width in px
      const dimensions = {
        height: width <= this.MOBILE ? '100%' : style.height,
        width: width <= this.MOBILE ? '100vw' : style.width,
      }

      const overRideStyles: CSS.Properties = {
        ...(width <= this.MOBILE && { borderRadius: 0 }),
      }

      Object.assign(container.style, dimensions, this.settings.containerStyle, overRideStyles)
    }
  }

  private onCollapseWidgetById(id: string, options: CloseWidgetOptions) {
    const { suppressCloseEvents } = options
    if (!suppressCloseEvents) {
      this.postActivityCollapseMessage(id)
    }
    this.state = {
      ...this.state,
      widgets: {
        ...this.state.widgets,
        [id]: {
          ...this.state.widgets[id],
          status: Status.collapsed,
        },
      },
    }
  }

  /**
   * Use this method to collapse the current activity.
   * Keeping this for backwards compatibility.
   * @deprecated use `onCollapseActivityById§ instead.
   */
  public onCollapseActivity() {
    const id = this.state.currentWidget.data.id
    this.postActivityCollapseMessage(id)
    this.state = {
      ...this.state,
      widgets: {
        ...this.state.widgets,
        [id]: {
          ...this.state.widgets[id],
          status: Status.collapsed,
        },
      },
    }
    this.closeCurrentWidget()
  }

  private postActivityCollapseMessage = (id: string) => {
    const widget = this.getWidget(id)
    const dist_url = this.getDistributionURL(widget.distribution_url)
    const iframe = this.getWidgetIframe(id)

    iframe.contentWindow.postMessage(
      {
        type: PostMessageCommands.ActivityCollapsed,
      },
      dist_url
    )
  }

  private onCompleteActivity = () => {
    const id = this.state.currentWidget.data.id
    this.state = {
      ...this.state,
      widgets: {
        ...this.state.widgets,
        [id]: {
          ...this.state.widgets[id],
          status: Status.completed,
        },
      },
    }
    if (this.settings.closeDelay && typeof this.settings.closeDelay === 'number') {
      setTimeout(() => {
        try {
          this.closeCurrentWidget()
        } catch (error) {
          if (!(error instanceof CannotCloseWidgetError)) {
            throw error
          }
        }
      }, this.settings.closeDelay)
    }
  }

  private getWidgetContainer = (id: string) => {
    const element = document.getElementById(`iframe-container-${id}`)
    return element
  }

  private getWidgetIframe = (id: string) => {
    const iframe = document.getElementById(`iframe-${id}`) as HTMLIFrameElement
    return iframe
  }

  /**
   * @throws CannotCloseWidgetError if the widget with @param id was not found in state.
   */
  public closeWidget = (id: string, options?: CloseWidgetOptions) => {
    const { suppressCloseEvents } = {
      ...defaultCloseWidgetOptions,
      ...options,
    }
    const widgetToClose = this.getWidget(id)

    if (!widgetToClose || !this.isWidgetOpen(widgetToClose)) {
      throw new CannotCloseWidgetError(id)
    }

    const element = this.getWidgetContainer(id)
    const { animation } = widgetToClose

    let _animation: Animation

    if (this.settings.animation) {
      // user changed the default animation
      _animation = this.settings.animation.exit
    } else {
      _animation = animation?.exit || PositionAnimationMap[widgetToClose.position].exit
    }

    if (!this.settings.disableAnimation) {
      element.classList.add(_animation)
    }

    element.onanimationend = () => {
      element.parentNode.removeChild(element)

      const maybeNextWidgetId = this.state.openWidgetIds?.[0]
      const maybeNextWidget = maybeNextWidgetId ? this.getWidget(maybeNextWidgetId) : undefined

      if (!!maybeNextWidget && maybeNextWidget.status === Status.new) {
        const iframe = this.getWidgetIframe(maybeNextWidgetId)
        const iframeContainer = this.getWidgetContainer(maybeNextWidgetId)

        this.state = {
          ...this.state,
          currentWidget: { data: maybeNextWidget, element: iframeContainer, iframe },
        }
      } else {
        this.state = {
          ...this.state,
          currentWidget: undefined,
        }
      }
    }

    this.onCollapseWidgetById(id, { suppressCloseEvents })
    this.removeWidgetFromOpenList(id)
  }

  private closeCurrentWidget = () => {
    const { currentWidget } = this.state

    if (!currentWidget) {
      throw new CannotCloseWidgetError('CURRENT_WIDGET')
    }

    const { data } = currentWidget
    this.closeWidget(data.id)
    this.onCollapseWidgetById(data.id, { suppressCloseEvents: false })
  }

  private setAnimationDuration: () => void = () => {
    const root = document.documentElement
    root.style.setProperty(
      '--touchpoint-animation-duration'.trim(),
      this.settings.animationDuration
    )
  }
  private getDistributionURL(url: string) {
    let dist_url = url
    // if dev maybe we want to use local distributor
    if (this.settings?.devDistributor) {
      const hash = url.substring(url.lastIndexOf('/'))
      dist_url = this.settings.devDistributor + hash
    }
    if (this.settings.locale) {
      dist_url = `${dist_url}/${this.settings.locale}`
    }
    dist_url += '?source=top_sdk'
    if (this.visitor.id) {
      dist_url += `&external_id=${this.visitor.id}`
    }
    if (this.visitor.localStorageId) {
      dist_url += `&sdk_local_storage_id=${this.visitor.localStorageId}`
    }
    if (this.publisher.app_id) {
      dist_url += `&app_id=${this.publisher.app_id}`
    }
    dist_url += '&scroll=off'
    return dist_url
  }
  private onReceiveMessage = (e: MessageEvent) => {
    const { type } = e.data

    if (!Object.values(PostMessageCommands).includes(type)) {
      // don't recognize the command
      return
    }
    if (!this.state.currentWidget) {
      // there is not dist App initiated yet
      return
    }
    const dist_url = this.getDistributionURL(this?.state?.currentWidget?.data?.distribution_url)
    if (!e.origin || e.origin !== new URL(dist_url).origin || !e.data.type) {
      // we don't recognize the origin the message is coming from
      return
    }
    if (type === PostMessageCommands.ActivityCompleted) {
      this.onCompleteActivity()
    }
    if (type === PostMessageCommands.TopIsReady && this?.state?.currentWidget?.element) {
      this.state.currentWidget.element.hidden = false
    }
  }
}

export default Touchpoint
