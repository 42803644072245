function isDocumentReady(fn: () => void) {
  if (document.readyState !== 'loading') {
    fn()
  } else if (window.addEventListener) {
    // window.addEventListener('load', fn);
    window.addEventListener('load', fn)
  }
}

function isLocalStorageAvailable(): boolean {
  const TEST = 'TEST'
  try {
    localStorage.setItem(TEST, TEST)
    localStorage.removeItem(TEST)
    return true
  } catch (error) {
    // Generating too many sentry alerts - if we have plans to use this data, we can turn back on
    // alert("Couldn't access local storage on WebSDK", false, {}, Severity.Warning)
    return false
  }
}

export function convertPathToRegexp(p: string): string {
  // change all * to .* to align with Regex
  if (!p) {
    return undefined
  }
  let path = p
  if (path === '') {
    path = '/'
  }
  path = p.replace('*', '.*')
  // add start and end
  path = '^' + path + '$'
  return path
}

export { isDocumentReady, isLocalStorageAvailable }

export const VCTraceID: {
  header: string
  sentryKey: string
} = {
  header: 'X-VCTraceId',
  sentryKey: 'vctraceid',
}
