import * as CSS from 'csstype'

interface Props {
  style?: CSS.Properties
  onClick?: (e: MouseEvent) => void
}
const CloseButton: (props: Props) => HTMLElement = ({ style = {}, onClick }) => {
  const button = document.createElement('button')
  button.className = 'touchpoint touchpoint__btn touchpoint__btn-close'
  button.onclick = onClick
  Object.assign(button.style, style)
  return button
}

export default CloseButton
