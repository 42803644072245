import { Position, Status, Widget, Pod, WidgetType } from '../types/shared'
import * as CSS from 'csstype'
import Visitor from './Visitor'
import Publisher from './Publisher'
import { convertPathToRegexp, VCTraceID } from '../utils/helpers'
import alert from '../utils/alert'
import { POD_API_URL } from '../utils/configs'
import { v4 as uuid } from 'uuid'

interface Props {
  publisher: Publisher
  visitor: Visitor
}
export interface UserAttributes {
  user_attributes: { [key: string]: string }
}

class API {
  public APIURL: string = ''
  private visitor: Visitor | undefined = undefined
  private publisher: Publisher
  constructor({ publisher, visitor }: Props) {
    this.APIURL = POD_API_URL[publisher.pod as Pod]
    this.visitor = visitor
    this.publisher = publisher
  }

  public getWidgets: () => Promise<{
    [widgetId: string]: Widget
  }> = () => {
    // here will send the visitor details and localstorageid if available
    const traceId = uuid()
    return fetch(
      `${this.APIURL}/distributor/distributor.Distributor/GetWidgets?app_id=${this.publisher.app_id}`,
      {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          [VCTraceID.header]: traceId,
        },
        body: JSON.stringify({
          app_id: this.publisher.app_id,
          // will leave id and local storage here as well until BE updates to consume the visitor payload are made
          // will probably just update the visitor service when the time comes
          ...(this?.visitor?.id && { visitor_id: this.visitor.id }),
          ...(this?.visitor.localStorageId && {
            local_storage_id: this.visitor.localStorageId,
          }),
          ...(this?.visitor?.other && {
            visitor: {
              visitor_id: this.visitor.id,
              local_storage_id: this.visitor.localStorageId,
              ...this.visitor.other,
            },
          }),
          location: window.location.href,
        }),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((data: { widgets: Widget[] }) => {
            const widgets = this.prepareWidgets(data)
            return widgets
          })
        } else if (response.status > 400) {
          return {}
        }
      })
      .catch((error) => {
        alert(error, true, { [VCTraceID.sentryKey]: traceId })
        return {}
      })
  }

  private prepareWidgets = (
    data:
      | {
          widgets: Widget[]
        }
      | undefined
  ): { [widgetId: string]: Widget } => {
    const widgets: { [widgetId: string]: Widget } | undefined = data?.widgets?.reduce(
      (accu: { [widgetId: string]: Widget }, current) => {
        return {
          ...accu,
          [current?.id]: {
            ...current,
            path:
              current.type === WidgetType.PathBased ? convertPathToRegexp(current.path) : undefined,
            pathsRegex:
              current.type === WidgetType.PathBased
                ? current.paths
                    .map((path) => {
                      return convertPathToRegexp(path)
                    })
                    .join(`|`)
                : undefined,

            status: Status.new,
          },
        }
      },
      {}
    )
    return widgets
  }

  public postUserAttributes = (user_attributes: UserAttributes) => {
    const traceId = uuid()
    return fetch(
      `${this.APIURL}/tracker/tracker.Tracker/UserAttributes?app_id=${this.publisher.app_id}`,
      {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          [VCTraceID.header]: traceId,
        },
        body: JSON.stringify({
          app_id: this.publisher.app_id,
          user_attributes,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else if (response.status > 400) {
          let BEError = {}
          if (response.headers.get('content-type')?.includes('application/json')) {
            response.json().then((data) => {
              BEError = data
              alert(new Error('Something went wrong posting UserAttributes'), true, {
                status: response.status,
                BEError,
                [VCTraceID.sentryKey]: traceId,
              })
            })
          } else {
            alert(new Error('Something went wrong posting UserAttributes'), true, {
              status: response.status,
              BEError,
              [VCTraceID.sentryKey]: traceId,
            })
          }
        }
      })
      .catch((error) => {
        alert(error, true, { [VCTraceID.sentryKey]: traceId })
        return {}
      })
  }
}

export default API
