import { Pod } from '../types/shared'

interface IPublisher {
  app_id: string
  pod: Pod
  [key: string]: string | number | boolean | undefined
}

class Publisher {
  public app_id: string
  public other: { [key: string]: string | number | boolean | undefined }
  public pod: Pod

  constructor({ app_id, pod, ...rest }: IPublisher) {
    this.app_id = app_id
    this.pod = pod
    this.other = { ...rest }
  }
}

export default Publisher
