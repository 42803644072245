import { v4 as uuid } from 'uuid'
import alert from '../utils/alert'

interface Props {
  id?: string
  [key: string]: string | number | boolean | undefined
}

type TrackingURLConfigs = undefined | 'off'

interface Options {
  disableTracking?: boolean
}
class Visitor {
  private readonly LOCAL_STORAGE_KEY = '__MAG__LOCAL__STORAGE__ID__'
  // disable tracking visitor id and local storage id more used for purpose of demoing and testing
  private disableTracking: boolean = false
  public id: string | undefined
  public localStorageId: string | undefined = undefined
  public other: { [key: string]: string | number | boolean | undefined }

  constructor({ id, localStoragePersistence, ...rest }: Props, options?: Options) {
    this.disableTracking = !!options?.disableTracking
    const trackingVisitor = this.getTrackingConfigFromURL()

    if (trackingVisitor === 'off') {
      this.disableTracking = true
    }
    if (!this.disableTracking) {
      this.id = id
      this.localStorageId = this.getVisitorIdFromLocalStorage()
    }
    this.other = { ...rest }
  }
  private getVisitorIdFromLocalStorage: () => string = () => {
    try {
      const id = localStorage.getItem(this.LOCAL_STORAGE_KEY)
      if (id) {
        return id
      }
      return this.setVisitorIdOnLocalStorage()
    } catch (error) {
      alert(error, false)
    }
  }
  private setVisitorIdOnLocalStorage: () => string = () => {
    try {
      const newId = uuid()
      localStorage.setItem(this.LOCAL_STORAGE_KEY, newId)
      return newId
    } catch (error) {
      alert(error, false)
      return undefined
    }
  }

  private getTrackingConfigFromURL: () => TrackingURLConfigs = () => {
    const params = new URLSearchParams(window.location.search)
    const top_tracking: string = params.get('top_tracking')
    return top_tracking as TrackingURLConfigs
  }
}

export default Visitor
