import { Pod } from '../types/shared'
import { POD_API_URL } from '../utils/configs'
import { VERSION } from './status'

export enum Severity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical',
}

export interface Report {
  app_id: string
  api_error: boolean
  pod_name: Pod
  level: Severity
  captured_exception: string
  app_name: string
  version: string
  extra: {
    stack?: string
    location: {
      hostname: string
      pathname: string
    }
  }
}

export default function alert(
  payload: Error | string,
  api_error: boolean = false,
  extra: { [key: string]: any } = {},
  severity: Severity = Severity.Error
) {
  let r: Report
  if (payload instanceof Error) {
    r = {
      app_id: window?.Touchpoint.publisher.app_id,
      pod_name: window?.Touchpoint.publisher.pod,
      app_name: 'APP_WEB_SDK',
      captured_exception: payload.message,
      version: VERSION,
      extra: {
        stack: payload.stack,
        location: {
          hostname: window.location.hostname,
          pathname: window.location.pathname,
        },
        ...extra,
      },
      level: Severity.Error,
      api_error,
    }
  } else {
    r = {
      app_id: window?.Touchpoint?.publisher?.app_id,
      pod_name: window?.Touchpoint?.publisher?.pod,
      app_name: 'APP_WEB_SDK',
      captured_exception: payload,
      version: VERSION,
      extra: {
        location: {
          hostname: window.location.hostname,
          pathname: window.location.pathname,
        },
        ...extra,
      },
      level: severity,
      api_error,
    }
  }

  fetch(`${POD_API_URL[window.Touchpoint.publisher.pod]}/tracker/tracker.Tracker/SentryProxy`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(r),
  })
}
