import { isDocumentReady } from './utils/helpers'
import Touchpoint from './services/Touchpoint'
import 'whatwg-fetch'

import './styles/variables.css'
import './styles/index.css'
import './styles/animations.css'

function main() {
  const top = new Touchpoint()
  window.Touchpoint = top
  if (window.topInit) {
    window.topInit()
  }
}

isDocumentReady(main)
