import { Pod } from '../types/shared'

// TODO: update SDK package configs to align with dash/dist
// duplicating this for now
const useNewUrl = true
const DEV_URL = useNewUrl ? 'alidalabs.com' : 'vcilabs.com'
const PROD_URL = useNewUrl ? 'alida.com' : 'visioncritical.com'

export const POD_API_URL: { [key in Pod]: string } = {
  dev1: `https://api-touchpoint.dev1.${DEV_URL}`,
  dev2: `https://api-touchpoint.dev2.${DEV_URL}`,
  sit1: `https://api-touchpoint.sit1.${DEV_URL}`,
  na1: `https://api-touchpoint.na1.${PROD_URL}`,
  na2: `https://api-touchpoint.na2.${PROD_URL}`,
  eu1: `https://api-touchpoint.eu1.${PROD_URL}`,
  eu2: `https://api-touchpoint.eu2.${PROD_URL}`,
  ap2: `https://api-touchpoint.ap2.${PROD_URL}`,
  ap3: `https://api-touchpoint.ap3.${PROD_URL}`,
  pub: `https://api-touchpoint.pub.${PROD_URL}`,
  local: `https://api-touchpoint.local.${DEV_URL}`,
}
