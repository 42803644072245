import * as CSS from 'csstype'
import { WidgetStyle, UserAttribute } from '@top/shared_deprecated/src/types/SDK'

export enum Animation {
  SlideInLeft = 'animation__slide-in-left',
  SlideInRight = 'animation__slide-in-right',
  SlideInUp = 'animation__slide-in-up',
  SlideInDown = 'animation__slide-in-down',
  SlideOutLeft = 'animation__slide-out-left',
  SlideOutRight = 'animation__slide-out-right',
  SlideOutUp = 'animation__slide-out-up',
  SlideOutDown = 'animation__slide-out-down',
  ZoomIn = 'animation__zoom-in',
  ZoomOut = 'animation__zoom-out',
  FadeIn = 'animation__fadeIn',
  FadeOut = 'animation__fadeOut',
  None = 'animation__none',
}

export interface WidgetAnimation {
  entrance: Animation
  exit: Animation
}

export enum Position {
  TopLeft = 'WIDGET_TOP_LEFT',
  TopRight = 'WIDGET_TOP_RIGHT',
  BottomLeft = 'WIDGET_BOTTOM_LEFT',
  BottomRight = 'WIDGET_BOTTOM_RIGHT',
  Centre = 'WIDGET_CENTRE',
  Embedded = 'WIDGET_EMBEDDED',
}

export enum Status {
  completed = 'WIDGET_COMPLETED',
  collapsed = 'WIDGET_COLLAPSED',
  new = 'WIDGET_NEW',
}

export interface Widget {
  pathsRegex: string
  paths: string[]
  path: string
  id: string
  distribution_url: string
  status: Status
  style?: WidgetStyle
  position?: Position
  animation?: WidgetAnimation
  delay_in_ms?: number
  is_active: boolean
  sdk_domain_app_id: string
  type: WidgetType
  targeting_rules: TargetingRules[]
  always_show: boolean
}

export enum WidgetType {
  PathBased = 'WIDGET_PATHBASED',
  Situational = 'WIDGET_SITUATIONAL',
}

export type AttributeValues = string | number | boolean | Date
interface TargetingRules {
  attribute: UserAttribute
  operator_type: OperatorType
  attribute_values: AttributeValues[]
}

export enum OperatorType {
  IS = 'OPERATOR_IS',
  IS_NOT = 'OPERATOR_IS_NOT',
  GT = 'OPERATOR_GT',
  GTE = 'OPERATOR_GTE',
  LT = 'OPERATOR_LT',
  LTE = 'OPERATOR_LTE',
  CONTAINS = 'OPERATOR_CONTAINS',
  DOES_NOT_CONTAIN = 'OPERATOR_DOES_NOT_CONTAIN',
  STARTS_WITH = 'OPERATOR_STARTS_WITH',
  ENDS_WITH = 'OPERATOR_ENDS_WITH',
  AFTER = 'OPERATOR_AFTER',
  ON_OR_AFTER = 'OPERATOR_ON_OR_AFTER',
  BEFORE = 'OPERATOR_BEFORE',
  ON_OR_BEFORE = 'OPERATOR_ON_OR_BEFORE',
}

export type Pod =
  | 'na1'
  | 'na2'
  | 'eu1'
  | 'eu2'
  | 'ap2'
  | 'ap3'
  | 'dev1'
  | 'dev2'
  | 'sit1'
  | 'pub'
  | 'local'
